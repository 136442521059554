import { SessionStorageService } from './SessionStorageService'

export const getToken = () => {
  if (window && window.sessionStorage) {
    const storage = new SessionStorageService()
    const t = storage.getToken()

    if (t) {
      return JSON.parse(t)
    }
  }

  return null
}
