import { uniq, flattenDeep } from 'lodash'

import { AuthorizedActionsForTypes } from './authorizedActions'
import { MenuItemsForTypes } from './menuItems'
import { RoleType, RoleTypeLevels } from './roleType'
import { CurrentUser } from 'src/mobile-app/rpcs/CurrentUser'

export { AuthorizedActions } from './authorizedActions'
export { MenuItems, MenuItemsForTypes } from './menuItems'
export { RoleType, allRoleTypes, nonFieldUserRoles } from './roleType'

export class RoleManager {
  public static getMenuItemsForRole(roles: string[]): Array<{ name: string }> {
    return uniq(
      flattenDeep(
        roles.map((r: string) => {
          if (MenuItemsForTypes[r]) {
            return MenuItemsForTypes[r]
          }

          return []
        }) as any
      )
    ) as Array<{ name: string }>
  }

  public static hasRole(user: CurrentUser, role: string): boolean {
    if (!role || !user || !user.roles || user.roles.length === 0) {
      return false
    }
    return user.roles.includes(role)
  }

  public static hasAnyRole(user: CurrentUser, roles: string[]): boolean {
    return roles.some(role => this.hasRole(user, role))
  }

  public static hasAllRoles(user: CurrentUser, roles: string[]): boolean {
    return roles.every(role => this.hasRole(user, role))
  }

  public static isAdmin(user: CurrentUser): boolean {
    return (
      RoleManager.hasRole(user, RoleType.SystemAdmin) ||
      RoleManager.hasRole(user, RoleType.AccountAdmin) ||
      RoleManager.hasRole(user, RoleType.FieldAdmin)
    )
  }

  public static isAccountAdmin(user: CurrentUser): boolean {
    return RoleManager.hasRole(user, RoleType.AccountAdmin)
  }

  public static hasAuthorizedAction(
    user: CurrentUser,
    action: string
  ): boolean {
    if (!action || !user || !user.roles || user.roles.length === 0) {
      return false
    }

    return user.roles.some((r: string) => {
      const authorizedActions = AuthorizedActionsForTypes[r] || []
      return !!authorizedActions.find(aa => aa === action)
    })
  }

  static getHighestRole(roles: string[]): string {
    const filteredRoles = roles.filter(role => {
      if (!role) {
        return false
      }
      return !isNaN(RoleTypeLevels[role])
    })
    return filteredRoles.reduce((acc, current) => {
      const level1 = RoleTypeLevels[acc]
      const level2 = RoleTypeLevels[current]
      if (level1 < level2) {
        return acc
      } else {
        return current
      }
    })
  }
}

export default RoleManager
