import { StaticImplements } from '../types/StaticImplements'

interface ErrorLogger {
  log(e: Error): void
}

@StaticImplements<ErrorLogger>()
class Logger {
  public static log(e: Error): void {
    console.error(e) // tslint:disable-line
  }
}

export default Logger
