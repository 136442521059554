import { User } from '@120wateraudit/envirio-components/dist/models'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  loginFailure,
  loginRequest,
  loginSuccess,
  updateProfileFailure,
  updateProfileRequest,
  updateProfileSuccess
} from '../actions/user'
import { APIProvider } from '../api'
import { pushRoute } from '../utils/navigation'
import { SessionStorageService } from '../utils/SessionStorageService'

interface LoginRequestOptions {
  type: string
  payload: {
    token: string
  }
}

interface UpdateProfileOptions {
  type: string
  payload: {
    firstName: string
    lastName: string
    currentPassword?: string
    password?: string
    passwordConfirmation?: string
    callback?(): void
  }
}

function* loginSaga({ payload: { token } }: LoginRequestOptions) {
  try {
    const storage = new SessionStorageService()

    storage.setToken(JSON.stringify(token))

    const user = yield call(APIProvider.getCurrentUser)

    storage.setUser(JSON.stringify(user))

    // Success and redirect to main dashboard
    yield put(loginSuccess({ user }))
    pushRoute('/')
  } catch (error) {
    let message = error.message
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      message = 'Incorrect username or password.'
    } else if (
      error.response &&
      error.response.status &&
      error.response.status === 403
    ) {
      message = '403'
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      message = error.response.data.message
    }
    yield put(loginFailure({ error: message }))
  }
}

function* updateProfileSaga({
  payload: {
    firstName,
    lastName,
    currentPassword,
    password,
    passwordConfirmation,
    callback
  }
}: UpdateProfileOptions) {
  try {
    const user: User = yield call(APIProvider.updateUserProfile, {
      firstName,
      lastName
    })

    // Save user to sessionStorage
    window.sessionStorage.setItem('120_schools_user', JSON.stringify(user))

    if (password && passwordConfirmation) {
      yield call(APIProvider.updateUserPassword, {
        currentPassword,
        password,
        passwordConfirm: passwordConfirmation
      })
    }

    if (typeof callback === 'function') {
      callback()
    }

    yield put(updateProfileSuccess({ user }))
  } catch (error) {
    yield put(updateProfileFailure({ error }))
  }
}

export default function* userSaga() {
  yield takeLatest(loginRequest.toString(), loginSaga)
  yield takeLatest(updateProfileRequest.toString(), updateProfileSaga)
}
