import { useAuth0 } from '@auth0/auth0-react'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Router, Switch } from 'react-router-dom'
import {
  User,
  SchoolAccount
} from '@120wateraudit/envirio-components/dist/models'

import * as Async from 'src/router/AsyncContainers'
import Paths from './routes'
import AppliedRoute from './AppliedRoute'
import AuthenticatedApp from './AuthenticatedApp'
import { getDefaultAccount } from '../selectors/accounts'
import { getUser } from '../selectors/user'
import { ApplicationState } from 'src/reducers'
import { browserHistory, pushRoute } from '../utils/navigation'
import { AsyncApp } from './AsyncContainers'
import RoleManager, {
  allRoleTypes,
  nonFieldUserRoles,
  RoleType
} from 'src/utils/RoleManager'
import { CurrentUser } from 'src/mobile-app/rpcs/CurrentUser'

interface RouteDefinition {
  path: unknown
  component: React.FC | React.Component
  allowedRoles: string[]
  exact?: boolean
}

const baseRouteDefinitions: RouteDefinition[] = [
  {
    path: Paths.Root,
    component: Async.AsyncProgramHub,
    allowedRoles: nonFieldUserRoles
  },
  {
    path: Paths.ProgramHub.index,
    component: Async.AsyncProgramHub,
    allowedRoles: nonFieldUserRoles
  },
  {
    path: Paths.Notifications.index,
    component: Async.AsyncNotifications,
    allowedRoles: allRoleTypes
  },

  // Planning
  {
    path: Paths.Planning.index,
    component: Async.AsyncPlanning,
    allowedRoles: nonFieldUserRoles
  },
  {
    path: Paths.Planning.samplingEventDetails,
    component: Async.AsyncSchoolSamplingEvent,
    exact: false,
    allowedRoles: nonFieldUserRoles
  },

  // Schools
  {
    path: Paths.Schools.index,
    component: Async.AsyncSchools,
    allowedRoles: nonFieldUserRoles
  },
  {
    path: Paths.Schools.schoolDetails,
    component: Async.AsyncSchool,
    exact: false,
    allowedRoles: nonFieldUserRoles
  },
  {
    path: Paths.Districts.index,
    component: Async.AsyncDistricts,
    allowedRoles: nonFieldUserRoles
  },
  {
    path: Paths.Districts.details,
    component: Async.AsyncDistrict,
    exact: false,
    allowedRoles: nonFieldUserRoles
  },

  // Field Collection
  {
    path: Paths.Collections.index,
    component: Async.AsyncCollections,
    allowedRoles: allRoleTypes
  },
  {
    path: Paths.Collections.samplingEventDetails,
    component: Async.AsyncFieldInfo,
    exact: false,
    allowedRoles: allRoleTypes
  },

  // Reporting
  {
    path: Paths.Reports.index,
    component: Async.AsyncReporting,
    allowedRoles: [RoleType.SystemAdmin, RoleType.AccountAdmin]
  },

  // Admin
  {
    path: Paths.Admin.manageFields,
    component: Async.AsyncManageFields,
    allowedRoles: allRoleTypes // todo: just admins,
  },
  {
    path: Paths.Admin.manageFieldsDetails,
    component: Async.AsyncManageFieldDetails,
    allowedRoles: allRoleTypes // todo: just admins,
  },
  {
    path: Paths.Admin.managePTDContent,
    component: Async.AsyncManagePTDContent,
    allowedRoles: allRoleTypes // todo: just admins,
  }
]

const NoAccessRedirect = ({}) => {
  pushRoute('/')
  return null
}

export const AuthenticatedRoutes: React.FC<{
  user?: User
  account?: SchoolAccount
  childProps: any
  routeDefinitions?: RouteDefinition[]
}> = ({
  user,
  childProps,
  account,
  routeDefinitions = baseRouteDefinitions
}) => {
  const { logout } = useAuth0()
  if (!user) {
    return null
  }

  if (user && !RoleManager.hasAnyRole(user as CurrentUser, allRoleTypes)) {
    logout({
      logoutParams: { returnTo: window.location.origin + '/login' }
    })
    return null
  }

  // Create route components
  const baseRoutes = routeDefinitions.map((r, i) => (
    <AppliedRoute
      key={`${r.path}-${i}`}
      exact={typeof r.exact === 'boolean' ? r.exact : true}
      path={r.path}
      props={{ ...childProps, account, user, to: Paths.ProgramHub.index }}
      component={
        RoleManager.hasAnyRole(user as CurrentUser, r.allowedRoles) ||
        r.path === Paths.Root ||
        r.path === Paths.ProgramHub.index
          ? r.component
          : NoAccessRedirect
      }
    />
  ))

  return <> {baseRoutes} </>
}

const App = AuthenticatedApp(AsyncApp)

interface Props {
  childProps: unknown
  account?: SchoolAccount
  user?: User
}

const Routes: FC<Props> = ({ childProps, account, user }) => {
  return (
    <Router history={browserHistory}>
      <Switch>
        <AppliedRoute
          exact
          path="/login"
          component={Async.AsyncLogin}
          props={{}}
        />
        <App user={user}>
          <AuthenticatedRoutes
            user={user}
            childProps={childProps}
            account={account}
          />
        </App>
      </Switch>
    </Router>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  // Only add application-wide required props here!
  account: getDefaultAccount(state),
  user: getUser(state)
})

export default connect(mapStateToProps)(Routes)
