enum LocalStorageKey {
  Token = '120_schools_token',
  User = '120_schools_user'
}

interface Storage {
  setItem(key: string, value: any): void
  getItem(key: string): string | null
  removeItem(key: string): void
}

export class SessionStorageService {
  constructor(storage: Storage = sessionStorage) {
    this.storage = storage
  }

  getUser(): string | null {
    return this.storage.getItem(LocalStorageKey.User)
  }

  setUser(user: string): void {
    this.storage.setItem(LocalStorageKey.User, user)
  }

  setToken(token: string): void {
    this.storage.setItem(LocalStorageKey.Token, token)
  }

  getToken(): string | null {
    return this.storage.getItem(LocalStorageKey.Token)
  }

  removeUser(): void {
    this.storage.removeItem(LocalStorageKey.User)
  }

  removeToken(): void {
    this.storage.removeItem(LocalStorageKey.Token)
  }

  private storage: Storage
}
