import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, RouteProps } from 'react-router-dom'
import { User } from '@120wateraudit/envirio-components/dist/models'

export default (Component: C) => (props: Props): JSX.Element | null => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const redirectRoute = `/login?redirect=${props.location!.pathname}${
    props.location!.search
  }`

  useEffect(() => {
    setLoading(prev => !prev)
  }, [])

  if (loading) {
    return null
  }

  return (
    (props.user && <Component {...props} />) || <Redirect to={redirectRoute} />
  )
}

interface Props extends RouteProps {
  user?: User
}

type C = (props: Props) => JSX.Element
